<template>
  <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
    <div class="card-header cursor-pointer">
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">User's personal details</h3>
      </div>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <button
          @click="saveData"
          class="btn btn-sm btn-success align-self-center"
        >
          Save
        </button>
        <button
          @click="$emit('discard')"
          class="btn btn-sm btn-danger align-self-center "
        >
          Discard
        </button>
      </div>
    </div>
    <div class="card-body p-9">
      <div class="row">
        <div class="col-8">
          <div class="row mb-3 align-items-center">
            <label class="col-lg-4 fw-bold text-muted">Birthday</label>
            <div class="col-lg-6">
              <el-date-picker
                v-model="data.birthday"
                size="large"
                value-format="DD/MM/YYYY"
                format="DD/MM/YYYY"
              ></el-date-picker>
            </div>
          </div>
          <div class="row mb-3 align-items-center">
            <label class="col-lg-4 fw-bold text-muted">Height(cm)</label>
            <div class="col-lg-6">
              <InputField
                v-model="data.height_cm"
                :placeholder="'Enter height in CM'"
              ></InputField>
            </div>
          </div>
          <div class="row mb-3 align-items-center">
            <label class="col-lg-4 fw-bold text-muted">Gender</label>
            <div class="col-lg-6">
              <InputField
                v-model="data.gender"
                :placeholder="'Enter gender'"
              ></InputField>
            </div>
          </div>
          <div class="row mb-3 align-items-center">
            <label class="col-lg-4 fw-bold text-muted">Nationality</label>
            <div class="col-lg-6">
              <InputField
                v-model="data.nationality"
                :placeholder="'Enter nationality'"
              ></InputField>
            </div>
          </div>

          <div class="row mb-3 align-items-center">
            <label class="col-lg-4 fw-bold text-muted">Address</label>

            <div class="col-lg-6">
              <InputField
                v-model="data.address"
                :placeholder="'Enter address'"
              ></InputField>
            </div>
          </div>

          <div class="row mb-3 align-items-center">
            <label class="col-lg-4 fw-bold text-muted">Languages</label>
            <div class="col-lg-6">
              <LanguagesDropdown v-model="data.languages"></LanguagesDropdown>
            </div>
          </div>

          <div class="row mb-3 align-items-center">
            <label class="col-lg-4 fw-bold text-muted">Allergies</label>
            <div class="col-lg-6">
              <el-input
                v-model="data.allergies"
                :autosize="{ minRows: 2, maxRows: 4 }"
                type="textarea"
                placeholder="Allergies"
              />
            </div>
          </div>

          <div class="row mb-3 align-items-center">
            <label class="col-lg-4 fw-bold text-muted">Vegan</label>
            <div class="col-lg-6">
              <el-switch v-model="data.is_vegan"  :active-value="1" :inactive-value="0"/>
            </div>
          </div>
          <div class="row mb-3 align-items-center">
            <label class="col-lg-4 fw-bold text-muted">Vegetarian</label>
            <div class="col-lg-6">
              <el-switch v-model="data.is_vegetarian" :active-value="1" :inactive-value="0"/>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div
            class="notice d-flex bg-light-warning rounded border-warning border border-dashed  p-6"
          >
            <span class="svg-icon svg-icon-2tx svg-icon-warning me-4">
              <inline-svg src="media/icons/duotone/Code/Warning-1-circle.svg" />
            </span>
            <div class="d-flex flex-stack flex-grow-1 ">
              <div class=" fw-bold">
                <h4 class="text-gray-800 fw-bolder">Emergency contact</h4>
                <div class="row">
                  <InputField
                    v-model="data.emergency_contact_name"
                    :placeholder="'Enter name'"
                    class="mb-4 col-12"
                  ></InputField>
                </div>
                <div class="row">
                  <InputField
                    v-model="data.emergency_contact_phone"
                    :placeholder="'Enter phone'"
                    class="mb-4 col-12"
                  ></InputField>
                </div>
                <div class="row">
                  <InputField
                    v-model="data.emergency_contact_relation"
                    :placeholder="'Enter relation'"
                    class="mb-4 col-12"
                  ></InputField>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
/* eslint-disable @typescript-eslint/camelcase */
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import LanguagesDropdown from "@/components/dropdown/LanguagesDropdown.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapGetters, mapActions } from "vuex";
import InputField from "@/components/general/InputField.vue"

export default defineComponent({
  name: "user-personal-edit",
  components: {
    InputField,
    LanguagesDropdown
  },
  data() {
    return {
      data: {
        birthday: "",
        address: "",
        nationality: "",
        height_cm: "",
        gender: "",
        emergency_contact_name: "",
        emergency_contact_phone: "",
        emergency_contact_relation: "",
        languages: '',
        is_vegan: false,
        is_vegetarian: false,
        allergies: ""
      },

    };
  },
  mounted() {
    this.data.address = this.getSingleUser.address;
    this.data.birthday = this.getSingleUser.birthday;
    this.data.height_cm = this.getSingleUser.height_cm;
    this.data.gender = this.getSingleUser.gender;
    this.data.nationality = this.getSingleUser.nationality;
    this.data.emergency_contact_name = this.getSingleUser.emergency_contact_name;
    this.data.emergency_contact_phone = this.getSingleUser.emergency_contact_phone;
    this.data.emergency_contact_relation = this.getSingleUser.emergency_contact_relation;
    this.data.is_vegan = this.getSingleUser.is_vegan;
    this.data.is_vegetarian = this.getSingleUser.is_vegetarian;
    this.data.allergies = this.getSingleUser.allergies;
    this.data.languages = this.getSingleUser.languages.map(language => language.id);
  },
  computed: {
    ...mapGetters("UsersModule", ["getSingleUser"])
  },
  methods: {
    ...mapActions("UsersModule", ["update"]),
    saveData() {
      const payload = {
        ...this.data,
        primary_branch_id : this.getSingleUser.branches.filter(branch => branch.pivot.is_primary == 1).map(branch => branch.id),
        branches : this.getSingleUser.branches.filter(branch => branch.pivot.is_primary == 0).map(branch => branch.id),
        first_name : this.getSingleUser.first_name,
        role : this.getSingleUser.role.map(role => role.id),
        photo : this.getSingleUser.photo
      }
      this.update({
        id: this.$route.params.id,
        payload: payload
      }).then(() => {
        Swal.fire({
          text: "The record has been successfully updated !",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary"
          }
        }).then(() => {
          this.$emit("saved")
        });
      }).catch(err => {
        Swal.fire({
          position: "top-end",
          showConfirmButton: false,
          title: err.response.data.message,
          icon: "error",
          toast: true,
          timer: 1000
        })
      })
    }
  },
});
</script>
