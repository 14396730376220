<template>
  <EditPersonal
    v-if="isEdited"
    @discard="isEdited = false"
    @saved="isEdited = false"
  ></EditPersonal>
  <div class="card mb-5 mb-xl-10" id="kt_profile_details_view" v-else>
    <div class="card-header cursor-pointer">
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">User's personal details</h3>
      </div>
      <button
        @click="isEdited = true"
        v-if="
          $can('edit_user_profile_info', 'all') ||
            currentUser.id == $route.params.id ||
            canEditProfile
        "
        class="btn btn-sm btn-primary align-self-center"
      >
        Edit
      </button>
    </div>
    <div class="card-body p-9">
      <div class="row">
        <div class="col-12 col-lg-8">
          <div class="row mb-7" v-if="getSingleUser.birthday">
            <label class="col-lg-4 fw-bold text-muted">Birthday</label>
            <div class="col-lg-8">
              <span class="fw-bold fs-6 text-dark"
                >{{ getSingleUser.birthday }}
              </span>
            </div>
          </div>
          <div class="row mb-7" v-if="getSingleUser.height_cm">
            <label class="col-lg-4 fw-bold text-muted">Height(cm)</label>
            <div class="col-lg-8 fv-row">
              <span class="fw-bold fs-6 text-dark"
                >{{ getSingleUser.height_cm }}
              </span>
            </div>
          </div>
          <div class="row mb-7" v-if="getSingleUser.gender">
            <label class="col-lg-4 fw-bold text-muted">Gender</label>
            <div class="col-lg-8 fv-row">
              <span class="fw-bold fs-6 text-dark"
                >{{ getSingleUser.gender }}
              </span>
            </div>
          </div>
          <div class="row mb-7" v-if="getSingleUser.nationality">
            <label class="col-lg-4 fw-bold text-muted">Nationality</label>
            <div class="col-lg-8">
              <span class="fw-bold fs-6 text-dark"
                >{{ getSingleUser.nationality }}
              </span>
            </div>
          </div>

          <div class="row mb-7" v-if="getSingleUser.is_vegetarian">
            <label class="col-lg-4 fw-bold text-muted">Vegetarian</label>
            <div class="col-lg-8">
              <span class="fw-bold fs-6 text-dark"
                >{{ getSingleUser.is_vegetarian ? "Yes" : "No" }}
              </span>
            </div>
          </div>

          <div class="row mb-7" v-if="getSingleUser.is_vegan">
            <label class="col-lg-4 fw-bold text-muted">Vegan</label>
            <div class="col-lg-8">
              <span class="fw-bold fs-6 text-dark"
                >{{ getSingleUser.is_vegan ? "Yes" : "No" }}
              </span>
            </div>
          </div>

          <div class="row mb-7" v-if="getSingleUser.allergies">
            <label class="col-lg-4 fw-bold text-muted">Allergies</label>
            <div class="col-lg-8">
              <span class="fw-bold fs-6 text-dark"
                >{{ getSingleUser.allergies }}
              </span>
            </div>
          </div>

          <div class="row mb-7" v-if="getSingleUser.address">
            <label class="col-lg-4 fw-bold text-muted">
              Address
            </label>
            <div class="col-lg-8">
              <span class="fw-bolder fs-6 text-dark"
                >{{ getSingleUser.address }}
                <br v-if="getSingleUser.address" />
                {{ getSingleUser.address ? getSingleUser.city : "" }}
              </span>
            </div>
          </div>
          <div class="row mb-7">
            <label class="col-lg-4 fw-bold text-muted">Active since</label>
            <div class="col-lg-8">
              <span class="fw-bolder fs-6 text-dark">{{
                getSingleUser.created_at
              }}</span>
            </div>
          </div>
          <div
            class="row mb-7"
            v-if="getSingleUser.languages && getSingleUser.languages.length"
          >
            <label class="col-lg-4 fw-bold text-muted">Spoken Languages</label>
            <div class="col-lg-8">
              <span
                class="fw-bolder fs-6 text-dark"
                v-for="(entry, index) in getSingleUser.languages"
                :key="entry.id"
              >
                <span v-if="index != 0">, </span>{{ entry.name }}
              </span>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div
            class="notice d-flex bg-light-warning rounded border-warning border border-dashed overflow-wrap-anywhere p-6"
          >
            <span class="svg-icon svg-icon-2tx svg-icon-warning me-4">
              <inline-svg src="media/icons/duotone/Code/Warning-1-circle.svg" />
            </span>
            <div class="d-flex flex-stack flex-grow-1 ">
              <div class=" fw-bold">
                <h4 class="text-gray-800 fw-bolder">Emergency contact</h4>
                <div class="row">
                  <a
                    class="fs-4 my-2  text-gray-600 col-12"
                    :href="'tel:' + getSingleUser.emergency_contact_phone"
                  >
                    {{
                      getSingleUser.emergency_contact_phone
                        ? "Phone: " + getSingleUser.emergency_contact_phone
                        : "No emergency contact entered"
                    }}
                  </a>
                </div>
                <div class="row">
                  <span
                    class="fs-4 text-gray-600 mb-2 col-12"
                    v-if="getSingleUser.emergency_contact_name"
                  >
                    {{ "Name: " + getSingleUser.emergency_contact_name }}
                  </span>
                </div>

                <div class="row">
                  <span
                    class="fs-4 text-gray-600"
                    v-if="getSingleUser.emergency_contact_relation"
                  >
                    {{
                      "Relation: " + getSingleUser.emergency_contact_relation
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import EditPersonal from "@/components/users/userProfile/fields/EditPersonal.vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "user-personal",
  components: {
    EditPersonal
  },
  data() {
    return {
      isEdited: false
    };
  },
  mounted() {
    setCurrentPageBreadcrumbs("Personal info", ["User"]);
  },
  methods: {
    canEditProfile() {
      let isWorker = false;
      if (this.getSingleUser && this.getSingleUser.role) {
        const roles = this.getSingleUser.role.map(role => role.title);
        isWorker = roles.includes("Worker");
      }
      return this.$can("can_edit_worker_profiles", "all") && isWorker;
    }
  },
  computed: {
    ...mapGetters("UsersModule", ["getSingleUser"]),
    ...mapGetters({ currentUser: "currentUser" })
  }
});
</script>

<style scoped>
.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}
</style>
